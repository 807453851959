<template>
    <div class="md-layout md-gutter pb-3">
        <div class="md-layout-item md-size-100 relative">
            <div class="overflow-hidden relative block">
                <BannerFlag
                    :flags="item && item.properties ? item.properties : []"
                    class="absolute"
                    titleKey="title"
                    colorKey="color_code"
                    :class="flagClasses"
                />

                <md-icon :class="[bookMarkClaass, bookMarkColorClass]" class="text-3xl absolute" style="right: 15px; top: 15px">bookmark</md-icon>
                <ImageView customClass="imageParentClass-302px" imageClass="imageCustomClass"  :imageUrl="imageUrl" />
            </div>
        </div>

        <CardSummary customClass="md-size-100" :item="item"/>

        <div class="md-layout-item md-size-100 mt-8 mb-6">
            <div class="flex align-center">
                <button @click="onGoToDetailPage()" class="px-1 py-2 md-size-30 bg-greenish-teal text-white text-base font-semibold rounded-lg rounded-br pointer">
                    Start Class
                </button>

                <p class="md-size-40 text-center font-semibold" :class="[item && item.is_completed ? 'text-success' : 'text-danger']">
                    <md-icon class="text-sm text-success" v-if="item && item.is_completed">check_circle</md-icon>
                    <md-icon class="text-sm text-danger" v-else>check_circle</md-icon>
                    {{ item && item.is_completed ? "Completed" : "Not Completed"}}
                </p>
                
            </div>
        </div>
    </div>
</template>

<script>
import CardSummary from '@/components/molecule/student/CardSummary.vue';
import ImageView from '@/components/atom/media/ImageView.vue';
import BannerFlag from "@/components/atom/BannerFlag.vue";
import defaultImage from "@/assets/images/news_placeholder_detailed_page.png";
export default {
    components: {
        CardSummary,
        ImageView,
        BannerFlag,
    },

    props: {
        flags: {
            type: Array,
            default: () => {
                return [
                    {
                        label: 'Featured',
                        color: '#00b915'
                    },
                    {
                        label: 'Vocabulary',
                        color: '#fa28a1'
                    },
                ]
            },
        },
        flagClasses: {
            type: String,
            default: 'default-flag-class'
        },
        bookMarkClaass: {
            type: String,
            default: 'text-white'
        },
        item: {
            type: Object,
            default: null
        }
    },
    computed:{
        bookMarkColorClass() {
            if(this.item && this.item?.is_bookmarked) {
                return 'text-tahiti-gold'
            }

            return 'text-white';
        },
        imageUrl() {
            if(this.item && this.item.thumbnail) {
                return this.item.thumbnail;
            }

            return defaultImage;
        }
    },
    methods: {
        onGoToDetailPage() {
            this.$router.push({
                name: 'student.onlineClass.details',
                params: {
                    id: this.item.id,
                }
            })
        }
    }
}
</script>

<style scoped>
.default-flag-class {
    transform: rotate(314deg);
    top: 9%;
    left: -38%;
}

img {
    max-height: 260px;
    width: 100%;
    object-fit: cover;
}
input {
    width: 60%;
    padding: 8px;
    border: 1px solid #cbc5c5;
    border-radius: 5px;
}
button {
    width: 110px;
    margin-left: -5px;
    border: none;
}
</style>