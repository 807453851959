<template>
    <div :class="customClass">
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-bold mt-5">{{ item?.title }}<span class="ml-3 text-xs font-light text-blue bg-trans-blue px-1 rounded">{{ senitizeDurationToHour }} Hours</span></h2>
            <p class="mt-2 text-sm line-height-27 height-74" v-html="item && item?.description.length > 120 ? item?.description.substring(0, 120) + '....' : item?.description"></p>
        </div>

        <div class="md-layout-item md-size-100 mt-6 flex">
            <div class="md-layout md-size-100">
                <div class="md-layout-item md-size-50 p-0">
                    <p class="font-semibold">
                        <md-icon class="text-blue text-sm">text_snippet</md-icon>
                        {{ item && item.total_chapters ? item.total_chapters : 0 }} Chapters
                    </p>
                </div>

                <div class="md-layout-item md-size-50">
                    <p class="font-semibold">
                        <md-icon class="text-blue text-sm">wysiwyg</md-icon>
                        {{ item && item.total_materials ? item.total_materials : 0 }} Materials
                    </p>
                </div>
            </div>
        </div>

        <div class="md-layout-item md-size-100 mt-3 flex">
            <div class="md-layout  md-size-100">
                <div class="md-layout-item md-size-50 p-0">
                    <p class="font-semibold">
                        <md-icon class="text-blue text-sm">mode_edit</md-icon>
                        {{ item && item.total_practice_tests ? item.total_practice_tests : 0 }} Tests
                    </p>
                </div>

                <div class="md-layout-item md-size-50">
                    <p class="font-semibold">
                        <md-icon class="text-blue text-sm">description</md-icon>
                        {{ item && item.total_quizes ? item.total_quizes : 0 }} Quizes
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { secondToHour } from '@/utils';
    export default {
        props: {
            customClass: {
                type: String
            },
            isDuplicate: {
                type: Boolean,
                default: false
            },
            item: {
                type: Object,
                default: null,
            }
        },
        computed: {
            senitizeDurationToHour() {
                if(this.item?.total_duration) {
                    return secondToHour(this.item.total_duration)
                }
                return '00';
            },
        }
    }
</script>