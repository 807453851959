<template>
<div class="aselect" :data-value="value" :data-list="list">
    <div class="selector" @click="toggle()">
        <div class="label">
                <span>{{ value ? value[titleKey] : placeholder }}</span>
        </div>
        <div class="arrow" :class="{ expanded : visible }"></div>
        <div :class="{ hidden : !visible, visible }">
            <ul>
                <li :class="{ current : item === value }" 
                    v-for="(item, index) in list" 
                    :key="item.id + index"
                    @click="select(item)"
                >
                    {{ item[titleKey] }}
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props: {
            list: {
                type: Array,
                default: () => [{id: 1, title:"Orange"},{id:2, title:"Apple"},{id: 2, title:"Kiwi"}, {id: 3, title:"Lemon"}, {id: 4, title:"Pineapple"}]
            },
            titleKey: {
                type: String,
                default: 'title',
            },
            placeholder: {
                type: String,
                default: 'Select One'
            },
            value: {
                type:  [String, Number, Object],
                default: function() {
                    if(!this.placeholder && this.items.length > 0) {
                    return this.items[0].id;
                    }
                }
            },
            returnType: {
                type: String,
                default: () => 'id'
            },
        },
        data() {
            return {
                visible: false
            }
		},
		methods: {
            toggle() {
                this.visible = !this.visible;
            },
            select(e) {
                let value = e;
                if(this.returnType == 'object') {
                    value = this.items.find(item => item.id == value);
                    this.$emit('getObject', value);
                }else if(this.returnType == 'index') {
                    this.$emit('getIndex', this.items.findIndex(item => item.id == value));
                }
                else {
                    this.$emit('input', value);
                } 
            }
		}   
    }
</script>

<style lang="scss" scoped>
.aselect {
		width: 280px;
		.selector {
			border: 1px solid gainsboro;
			background: #F8F8F8;
			position: relative;
			z-index: 1000;
			.arrow {
				position: absolute;
				right: 10px;
				top: 40%;
				width: 0;
				height: 0;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-top: 10px solid #888;
				transform: rotateZ(0deg) translateY(0px);
				transition-duration: 0.3s;
				transition-timing-function: cubic-bezier(.59,1.39,.37,1.01);
			}
			.expanded {
				transform: rotateZ(180deg) translateY(2px);
			}
			.label {
				display: block;
				padding: 12px;
				font-size: 18px;
                font-weight: 600;
				color: #000;
			}
		}
		ul {
			width: 100%;
			list-style-type: none;
            padding: 0;
            margin: 0;
			font-size: 16px;
            font-weight: 600;
			border: 1px solid gainsboro;
			position: absolute;
			z-index: 1;
            background: #fff;
		}
		li {
			padding: 12px;
			color: #666;
			&:hover {
				color: #fa28a1;
				background: #e1eef7;
			}
            &:active {
                color: #fa28a1;
            }
		}
		.current {
            color: #fa28a1;
		}
		.hidden {
			visibility: hidden;
		}
		.visible {
			visibility: visible;
		}
	}
</style>