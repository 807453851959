<template>
    <div class="md-layout flex justify-between flex-row">
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-bold mt-5 flex align-center">
                {{ headerTitle }}
            </h2>
        </div>

        <ClassGridItem class="md-layout-item mt-6 md-size-33" v-for="(item, i) in items" :item="item" :key="i" />
    </div>
</template>

<script>
import ClassGridItem from '@/components/molecule/student/online-class/ClassGridItem.vue';

export default {
    components: {
        ClassGridItem
    },
    props: {
        headerTitle: {
            type: String,
            default: 'More Classes'
        },
        items: {
            type: Array,
            default:() => {return []}
        }
    },
}
</script>