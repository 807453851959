<template>
    <div :class="customClass">
        <img :class="imageClass" class="rounded-lg" :src="imageUrl"/>
    </div>
</template>

<script>
export default {
    props: {
        customClass: {
            type: String
        },
        imageClass: {
            type: String
        },
        imageUrl: {
            type: String,
            default: 'https://img.jakpost.net/c/2020/03/01/2020_03_01_87874_1583031914._large.jpg'
        }
    },
}
</script>