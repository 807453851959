<template>
    <div class="md-layout md-gutter pb-3">
        <div class="md-layout-item md-size-100 relative">
            <div class="overflow-hidden relative block">
                <BannerFlag
                    :flags="flags"
                    class="absolute"
                    :class="flagClasses"
                />

                <md-icon :class="bookMarkClaass" class="text-3xl absolute" style="right: 15px; top: 15px">bookmark</md-icon>
                <ImageView customClass="w-100p" />
            </div>
        </div>

        <CardSummary customClass="md-size-100"/>

        <div class="md-layout-item md-size-100 mt-8 mb-6">
            <div class="flex align-center">
                <button class="px-1 py-2 md-size-30 bg-greenish-teal text-white text-base font-semibold rounded-lg rounded-br">
                    Start Class
                </button>

                <p class="md-size-30 text-center text-success font-semibold">
                    <md-icon class="text-blue text-sm text-success">check_circle</md-icon>
                    Completed
                </p>
                
            </div>
        </div>
    </div>
</template>

<script>
import CardSummary from '@/components/molecule/student/CardSummary.vue';
import ImageView from '@/components/atom/media/ImageView.vue';
import BannerFlag from "@/components/atom/BannerFlag.vue";

export default {
    components: {
        CardSummary,
        ImageView,
        BannerFlag,
    },

    props: {
        flags: {
            type: Array,
            default: () => {
                return [
                    {
                        label: 'Featured',
                        color: '#00b915'
                    },
                    {
                        label: 'Vocabulary',
                        color: '#fa28a1'
                    },
                ]
            },
        },
        flagClasses: {
            type: String,
            default: 'default-flag-class'
        },
        bookMarkClaass: {
            type: String,
            default: 'text-white'
        }
    },
    methods: {
        onGoToDetailPage() {
            this.$router.push({
                name: 'student.onlineClass.details',
                params: {
                    id: "id-123"
                }
            })
        }
    }
}
</script>


<style scoped>
.default-flag-class {
    transform: rotate(314deg);
    top: 9%;
    left: -38%;
}

img {
    max-height: 260px;
    width: 100%;
    object-fit: cover;
}
input {
    width: 60%;
    padding: 8px;
    border: 1px solid #cbc5c5;
    border-radius: 5px;
}
button {
    width: 110px;
    margin-left: -5px;
    border: none;
}
</style>