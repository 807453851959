<template>
  <form @submit.prevent="$emit('submit',keyWord)" class="p-1 combosearch-box-area relative outline-gray-400">
    <div class="flex rounded px-1 cursor">
      <md-icon v-if="isMultiIcon" class="m-0 pt-4 search-icon disable_search" @click.native="$emit('submit',keyWord)">search</md-icon>
      <input class="search-box" :placeholder="placeholder" v-model="keyWord" type="text" @input="$emit('input', $event.target.value)" />
      <md-icon v-if="!isMultiIcon" class="m-0 pb-1 search-icon" >search</md-icon>
      <md-icon v-if="isMultiIcon && !isFilter" class="m-0 filter_alt-icon rounded" @click.native="onMoreOptionShow()">filter_alt</md-icon>
      <md-icon v-if="isMultiIcon && isFilter" class="m-0 close-icon" @click.native="onMoreOptionShow()">close</md-icon>
    </div>
    
    <div v-show="isFilter" class="combosearch-result-area px-4 pb-4">
      <md-divider class="my-2"></md-divider>
      <span class="text-gray font-semibold text-uppercase">Suggetions</span>
      <div class="flex flex-col">
        <span
          class="font-medium text-sm line-height-33 pointer"
          v-for="(result, index) in results"
          :key="index"
          @click="onSetSuggestion(result[resultKey])"
        >
          {{ result[resultKey] }}
        </span>
      </div>

      <md-divider class="my-2"></md-divider>
      <div class="flex flex-col">
        <span class="text-gray font-semibold text-uppercase">Filters</span>
        <span class="font-medium text-sm my-2">Class Duration (Hour)</span>

        <input type="range" min="0" max="150" step="25" v-model="rangeSlider" @input="$emit('onFilterByDuration', $event.target.value)"> 
        <div class="flex">
          <span class="font-bold w-20p text-sm">0</span>
          <span class="font-bold w-20p text-sm">25</span>
          <span class="font-bold w-20p text-sm">50</span>
          <span class="font-bold w-20p text-sm">75</span>
          <span class="font-bold w-20p text-sm">100</span>
          <span class="font-bold w-20p text-sm text-right">150+</span>
        </div>
      </div>

      <div class="flex flex-col my-3">
        <span class="font-medium text-sm my-2">Sort By Number Of Chapter</span>
        <md-radio class="c-radio_btn text-sm m-1" 
          v-for="(item, i) in sorts" 
          :key="i" 
          v-model="selectedSort" 
          :value="item.id"
        >
          {{ item.label }}
        </md-radio>
      </div>

      <span class="font-medium text-sm text-sky text-underline pointer" @click="onReset">Reset all filters</span>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String
    },
    results: {
      default: () => []
    },
    resultKey: {
      type: String
    },
    value: {
      type: String
    },
    isMultiIcon: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isFilter: false,
      rangeSlider: 75,
      selectedSort: 'ALL',
      sorts: [
        {
          label: 'All',
          id: 'ALL',
        },
        {
          label: 'Small',
          id: 'SMALL',
        },
        {
          label: 'Medium',
          id: 'MEDIUM',
        },
        {
          label: 'Large',
          id: 'LARGE',
        }
      ],
      keyWord: "",
    }
  },
  methods: {
    onMoreOptionShow() {
      this.isFilter = !this.isFilter;
    },
    onReset() {
      this.isFilter = false;
      this.$emit('onResetSearch');
      this.rangeSlider = 75;
      this.keyWord = '';
      this.selectedSort = 'ALL';
    },
    onSetSuggestion(sugg) {
      this.keyWord = sugg;
      this.$emit('submit',this.keyWord);
    }
  },
  watch: {
    selectedSort: {
      deep: true,
      handler(val) {
        if(val && this.isFilter) {
          this.$emit('onUpdateSortByNumberOfChapter', val);
        }
      }
    }
  }
};
</script>

<style lang="scss">
  .combosearch-box-area {
    border-radius: 4px;

    .c-radio_btn {
        .md-radio-container {
            border: 1px solid #2459ef !important;
            
        }

        .md-radio-container::after {
            background-color: #2459ef !important;
        }
    }

    .filter_alt-icon{
      background: #762cb5;;
      color: #fff !important;
      padding: 3px;
      height: 34px !important;
      width: 40px !important;
    }

    .close-icon {
      padding: 3px;
      height: 34px !important;
      width: 40px !important;
    }
  }
  .combosearch-result-area {
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    top: 38px;
    background: #fff;
    z-index: 222;
    border: 1px solid #cbd5e0;
    border-top-color: transparent;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // max-height: 250px;
    overflow-y: auto;
    li {
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background-color: rgb(221, 220, 220);
      }
    }
    
  }
  .search-box {
    border: none;
    width: 100%;
    outline: none;
    background-color: inherit;
  }
  .search-icon {
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    &:hover {
      background-color: rgba(235, 235, 235, 0.603)
    }
  }

//step range
input[type=range] {
  height: 26px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #2459ef;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2459ef;
  height: 19px;
  width: 18px;
  border-radius: 31px;
  background: #2459ef;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2459ef;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #dbdbdb;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2459ef;
  height: 19px;
  width: 18px;
  border-radius: 31px;
  background: #2459ef;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  // background: #2459ef;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  // background: #2459ef;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  // border: 1px solid #2459ef;
  height: 19px;
  width: 18px;
  border-radius: 31px;
  background: #2459ef;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #2459ef;
}
input[type=range]:focus::-ms-fill-upper {
  background: #2459ef;
}

</style>
