<template>
    <div class="w-100p">
        <carousel
            :responsive="responsive"
            @changed="changed" 
            @updated="updated"
            :autoplay="autoplay"
            :nav="nav"
        >
            <slot></slot>
        </carousel>
    </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
    components: {
        carousel
    },

    props: {
        responsive: {
            type: Object,
            default:() => {
                return {
                    0: {
                        items: 1,
                        nav: false,
                        dots: false
                    },
                    600: {
                        items:3.5,
                        nav: false,
                        dots: false
                    },
                }
            }
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        nav: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        changed(param) {
            this.$emit('changed', param);
        },

        updated(param) {
            this.$emit('updated', param);
        },

    }
}
</script>