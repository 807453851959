<template>
    <div class="flex flex-col ">
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-bold mt-5 flex align-center">
                <span class="material-icons text-green">
                    star
                </span>

                {{ headerTitle }}
            </h2>
        </div>

        <div class="relative w-100p mt-6" v-if="items.length">
            <CarouselView
                :responsive="responsive"
            >
                <ClassGridItem class="md-layout-item md-size-100 mt-1" v-for="(item, i) in items" :item="item" :key="i" />
            </CarouselView>
        </div>


        <div class="md-layout flex justify-between flex-row" v-else>
            <DummyClassGridItem class="md-layout-item mt-6 md-size-33" v-for="(item, i) in 3" :key="i" />
        </div>
    </div>
</template>

<script>
import ClassGridItem from '@/components/molecule/student/online-class/ClassGridItem.vue';
import DummyClassGridItem from '@/components/molecule/student/online-class/DummyClassGridItem.vue';
import CarouselView from '@/components/atom/CarouselView.vue';

export default {
    components: {
        ClassGridItem,
        CarouselView,
        DummyClassGridItem
    },
    props: {
        headerTitle: {
            type: String,
            default: 'Featured Class'
        },
        items: {
            type: Array,
            default:() => {return [
            ]},
        }
    },
    data(){
        return {
            responsive: {
                0: {
                    items: 1,
                    nav: false,
                    dots: false
                },
                600: {
                    items:3.5,
                    nav: false,
                    dots: true
                },
            },
        }
    }
}
</script>